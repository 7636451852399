import React from "react"
import Navbar from "../components/navbar"
import styled from "styled-components"

export default function Bookmark() {
    return (
      <>
          <Navbar></Navbar>
      </>
    )
  }